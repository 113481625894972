import React, { useContext } from "react";
import { useSelector } from "react-redux";
import store from "../../redux/configure-store";
import { setCart } from "../../redux/ducks/cart";
import { addToCart } from '../../api/cart';
import { addFavorite, removeFavorite } from "../../api/favorite";
import { setFavorite } from "../../redux/ducks/favorite";
import { AlertContext } from '../Alerts/AlertsContext';
import {
  RiHeartFill,
  RiHeartAddLine,
  RiShareLine,
  RiShoppingCart2Line,
} from "react-icons/ri";
import PropTypes from "prop-types";

// import { toast } from "react-toastify";
// import { useLanguage } from "../../../hooks/useLanguage";

const ProductAction = ({ product }) => {
  // const { t } = useLanguage();
  // const { theme } = useTheme();

  const favoriteItems = useSelector((state) => state.favorite);
  const isInFavorite = favoriteItems?.length ? favoriteItems?.find((item) => item.product_id === product.id) : null;
  const FavoriteIcon = isInFavorite ? RiHeartFill : RiHeartAddLine;
  const { showAlert } = useContext(AlertContext);

  const addToCartHandler = async (event) => {
    event.stopPropagation();
    if (product?.virtual_inventory <= 0) {
      showAlert('info', '已售罄，没有添加购物车');
    }
    try {
      const response = await addToCart({ product_id: product.id, quantity: 1 });
      store.dispatch(setCart(response.data.carts));
      showAlert('success', '已添加到购物车');
    } catch (error) {
      if (error === "Unauthenticated.") {
        showAlert('info', '请先登录');
      }
    }
  }

  const toggleFavoriteHandler = async (event) => {
    event.stopPropagation();
    if (isInFavorite) {
      const response = await removeFavorite({ product_id: product.id });
      await store.dispatch(
        setFavorite(response?.data?.favorites)
      );
      showAlert('success', '已移除心愿清单');
    } else {
      try {
        const response = await addFavorite({ product_id: product.id });
        await store.dispatch(
          setFavorite(response?.data?.favorites)
        );
        showAlert('success', '已加入心愿清单');
      } catch (error) {
        if (error === "Unauthenticated.") {
          showAlert('info', '请先登录');
        }
      }
    }
  }

  const handleShareProduct = (event) => {
    event.stopPropagation();
    //复制url链接
    const productUrl = `${window.location.origin}/product/${product.id}`;
    navigator.clipboard.writeText(productUrl);
    showAlert('success', '复制到剪贴板');
  };

  return (
    <div className="w-4/5 w-auto h-[120px] md:h-[130px] mt-2 p-2 flex flex-col justify-around self-center absolute left-2 top-6 md:top-8 md:bottom-auto left-0  md:left-2 rounded-lg md:rounded-full shadow-lg backdrop-filter backdrop-blur-[8px] bg-palette-card/20  ">
      <div
        className="cursor-pointer hover:text-rose-600 transition-colors sm:px-3 md:px-0"
        onClick={toggleFavoriteHandler}
      >
        <FavoriteIcon
          style={{
            fontSize: "1.2rem",
            fill: `${isInFavorite ? "#ee384e" : ""}`,
          }}
        />
      </div>
      <div
        className="cursor-pointer hover:text-rose-600 sm:px-3 md:px-0"
        onClick={handleShareProduct} >
        <RiShareLine style={{ fontSize: "1.2rem" }} />
      </div>
      {!product?.product_variants?.length && product.virtual_inventory > 0 ? <div
        className="cursor-pointer hover:text-rose-600 active:scale-125 transition-all sm:px-3 md:px-0"
        onClick={addToCartHandler}
      >
        <RiShoppingCart2Line
          style={{
            fontSize: "1.2rem",
          }}
        />
      </div> : <div
        className="hover:text-rose-600 active:scale-125 transition-all sm:px-3 md:px-0"
      >
        <RiShoppingCart2Line
          style={{
            visibility: "hidden",
            fontSize: "1.2rem",
          }}
        />
      </div>}
    </div>
  );
};

ProductAction.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductAction;
