import React, { useEffect, useState } from "react";
import BrandBox from "./brandBox";
import Slider from "react-slick";
import { getBrandList } from "../../api/brand";
import { NextArrow, PrevArrow } from "./arrows";

const Brands = () => {
  const [brandContent, setBrandContent] = useState([]);

  const settings = {
    infinite: true,
    speed: 6000,
    slidesToShow: Math.min(8, brandContent?.length),
    slidesToScroll: Math.min(4, brandContent?.length),
    autoplay: false,
    autoplaySpeed: 8000,
    cssEase: "linear",
    nextArrow: <NextArrow to="next" />,
    prevArrow: <PrevArrow to="prev" />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBrandList();
        setBrandContent(response.brands);
      } catch (error) {
        console.error("Error", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="hidden sm:block mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 py-6 lg:py-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4 lg:mb-6">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-4 md:mb-0">精选品牌</h2>
      </div>
      <Slider {...settings}>
        {brandContent.map((brandItem) => (
          <BrandBox
            key={brandItem.id}
            id={brandItem.id}
            brandName={brandItem.name}
            imageSrc={brandItem.avatar}
          />
        ))}
      </Slider>
    </div >
  );

};

export default Brands;
