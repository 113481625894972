import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import Slide from "./slide";
import Loading from '../Loading';
// import { NextArrow, PrevArrow } from "./arrows";
// import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import { getBannerList } from '../../api/banners';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getBannerList();
        setBanners(response.banners);
        setLoading(false);
      } catch (error) {
        console.error("Error", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    // nextArrow: <NextArrow to="next" />,
    // prevArrow: <PrevArrow to="prev" />,
    appendDots: (dots) => (
      <div className="bg-transparent !pb-[40px]">
        <ul> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className="relative w-[95%] aspect-ratio mx-auto">
      {loading ? <Loading isLoading={loading} /> : null}
      {banners.length ?
        <Slider {...settings}>
          {banners?.map((slideContent) => {
            return <Slide key={slideContent.id} {...slideContent} />;
          })}
        </Slider>
        :
        <div className="placeholder bg-gray-200 w-[100%] aspect-ratio"></div>
      }
      {/* <div className="absolute top-1/2 right-4 md:right-3 lg:right-8 shadow-lg rounded-full bg-palette-card/80 p-1 drop-shadow-lg text-[0.8rem] md:text-[1.8rem]">
        <HiOutlineChevronRight />
      </div>
      <div className="absolute top-1/2 left-4 md:left-3 lg:left-8 shadow-lg rounded-full bg-palette-card/80 p-1 drop-shadow-lg text-[0.8rem] md:text-[1.8rem]">
        <HiOutlineChevronLeft />
      </div> */}
    </div>
  );
};

export default Banner;
