import React, { Fragment, useEffect, useState } from 'react';
import {
    getShippingAddressList,
    createShippingAddress,
    deleteShippingAddress,
    setDefaultShippingAddress,
    updateShippingAddress
} from '../api/shipping-address';
import { UserIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react';
import CustomInput from '../components/CustomInput';
import { checkoutSchema } from "../schemas/checkoutSchema";
import { Formik, Form } from 'formik';

const AddressManagement = () => {
    const [addresses, setAddresses] = useState([]);
    const [editNewAddress, setEditNewAddress] = useState(false);
    const [editAddress, setEditAddress] = useState("");

    const handleNewAddress = () => {
        setEditNewAddress(true);
        setEditAddress("");
    }

    const handleDeleteAddress = async (id) => {
        try {
            await deleteShippingAddress(id);
            const response = await getShippingAddressList(); // 重新获取地址列表
            setAddresses(response.data.shipping_addresses.data);
        } catch (error) {
            console.error('Error', error);
        }
    };

    const handleEditAddress = async (address) => {
        setEditNewAddress(true);
        setEditAddress(address)
    }


    const handleDefaultAddress = async (id) => {
        try {
            await setDefaultShippingAddress(id);
            const response = await getShippingAddressList(); // 重新获取地址列表
            setAddresses(response.data.shipping_addresses.data);
        } catch (error) {
            console.error('Error', error);
        }
    }

    const fetchAddressList = async () => {
        try {
            const response = await getShippingAddressList();
            const addressData = response.data.shipping_addresses.data;
            //address.is_default是放第一个，其他的放后面
            const defaultAddress = addressData.sort((a) => {
                if (a.is_default) {
                    return -1;
                }
                return 1;
            });
            setAddresses(defaultAddress);
        } catch (error) {
            console.error('Error', error);
        }
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const shippingInfo = {
            shipping_address: values['recipient-address'],
            shipping_city: values['recipient-city'],
            shipping_contact: values['recipient-phone'],
            shipping_country: values['recipient-country'],
            shipping_id: values['recipient-id'],
            shipping_name: values['recipient-name'],
        };

        try {
            if (editAddress?.id) {
                await updateShippingAddress(editAddress.id, shippingInfo);
            } else {
                await createShippingAddress(shippingInfo);
            }
            await fetchAddressList();
            setEditNewAddress(false);
            resetForm(); // 重置表单
        } catch (error) {
            console.error('Error', error);
        } finally {
            setSubmitting(false); // 关闭提交状态
        }
    };


    useEffect(() => {
        fetchAddressList()
    }, []);

    return (
        <div className="">
            <div className="bg-gray-50 mx-auto max-w-2xl sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8 p-4">
                <div className="">
                    <div className="space-y-8">
                        <button
                            className="text-blue-500 hover:underline"
                            onClick={() => handleNewAddress()}
                        >
                            新建地址
                        </button>
                        <Transition appear show={editNewAddress} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed z-10 inset-0 overflow-y-auto"
                                onClose={setEditNewAddress}
                            >
                                <div className="min-h-screen px-4 text-center">
                                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-40" />

                                    <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

                                    <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title className="text-xl font-medium text-gray-900">
                                            {editAddress?.id ? "编辑收件人地址" : "新建收件人地址"}
                                        </Dialog.Title>
                                        <Formik
                                            initialValues={{
                                                'sender-name': '',
                                                'sender-phone': '',
                                                'sender-address': '',
                                                'recipient-name': editAddress.shipping_name || "",
                                                'recipient-phone': editAddress.shipping_contact || "",
                                                'recipient-address': editAddress.shipping_address || "",
                                                'recipient-city': editAddress.shipping_city || "",
                                                'recipient-country': editAddress.shipping_country || "中国",
                                                'recipient-id': editAddress.shipping_id || "",
                                                shipping_type: 'oversea-direct', // 默认发货方式
                                            }}
                                            validationSchema={checkoutSchema}
                                            onSubmit={handleSubmit}
                                        >
                                            {({ isSubmitting }) => (
                                                <Form >
                                                    <div className="my-6 border-t border-gray-200">
                                                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                            <CustomInput name="recipient-name" label="名字" type="text" />
                                                            <CustomInput name="recipient-phone" label="联系电话" type="text" />
                                                        </div>
                                                        <CustomInput name="recipient-address" label="地址" type="text" />
                                                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                            <CustomInput name="recipient-city" label="市/省" type="text" />
                                                            <CustomInput name="recipient-country" label="国家" type="select">
                                                                <option value="中国">中国</option>
                                                                <option value="New Zealand">New Zealand</option>
                                                            </CustomInput>
                                                        </div>
                                                        {/* <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                            <CustomInput name="recipient-id" label="身份证号码" type="text" />
                                                            <div></div>
                                                        </div> */}

                                                    </div>
                                                    <div className="mt-4">
                                                        <button
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                        >
                                                            {isSubmitting ? '更新中...' : editAddress?.id ? "更新地址" : "新建地址"}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            disabled={isSubmitting}
                                                            className="ml-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                                                            onClick={() => setEditNewAddress(false)}
                                                        >
                                                            取消
                                                        </button>
                                                    </div>
                                                </Form>
                                            )
                                            }
                                        </Formik >
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>
                        {/* Display editable addresses */}
                        {addresses.map((address) => (
                            <div
                                key={address.id}
                                className="bg-white shadow-sm sm:rounded-lg"
                            >
                                <div className="bg-white shadow-sm sm:rounded-lg mb-4 p-4 flex">
                                    <div className="flex-1">
                                        <div className="flex items-center mb-4">
                                            <UserIcon className="h-6 w-6 mr-2 text-gray-500" />
                                            <span className="font-semibold">{address.shipping_name}</span>
                                            {/* <span className="ml-2">{address.shipping_contact}</span> */}
                                            {address.is_default && (
                                                <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-green-500 rounded-full">
                                                    默认
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            {/* <p>身份证：{address.shipping_id}</p> */}
                                            <p>联系方式：{address.shipping_contact}</p>
                                            <p>国家城市：{address.shipping_country}, {address.shipping_city}</p>
                                            <p>地址：{address.shipping_address}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between items-end">
                                        {!address.is_default &&
                                            <button
                                                className="text-blue-500 hover:underline mb-2"
                                                onClick={() => handleDefaultAddress(address.id)}
                                            >
                                                设置为默认
                                            </button>
                                        }
                                        <div className="flex space-x-2">
                                            <button
                                                className="text-blue-500 hover:underline"
                                                onClick={() => handleEditAddress(address)}
                                            >
                                                编辑
                                            </button>
                                            <button
                                                className="text-red-500 hover:underline"
                                                onClick={() => handleDeleteAddress(address.id)}
                                            >
                                                删除
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressManagement;
