import React, { useState, useEffect } from 'react';
import { getArticleList } from '../api/article';

function Footer() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        getArticleList()
            .then(response => {
                setArticles(response.data.articles);
            })
            .catch(error => {
                console.error("Error fetching articles:", error);
            });
    }, []);

    return (
        <footer aria-labelledby="footer-heading" className="bg-white py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {articles.length > 0 && (
                    <div>
                        {/* <h3 className="text-lg font-semibold text-center text-gray-900">最新文章</h3> */}
                        <ul className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {articles.map((item) => (
                                <li key={item.title} className="text-sm text-center">
                                    <a href={`/article/${item.id}`} className="text-gray-500 hover:text-gray-600">
                                        {item.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="mt-8 border-t border-gray-200 pt-6 text-center">
                    <p className="text-sm text-gray-500">© 2023 - LingLink Limited</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

